import React, { FC } from 'react';

import { Box } from '@/common/components/Display/Box';
import { HtmlRenderer } from '@/common/components/HtmlRenderer';
import { Image } from '@/common/components/Image';
import { PoweredBy } from '@/common/components/PoweredBy';

import { useFrontPage, useFrontSite } from '../Store';
import style from './SiteFooter.module.css';
import { SiteFooterSocial } from './SiteFooterSocial';

export const SiteFooter: FC = () => {
  const siteProperties = useFrontSite((x) => x.site.properties);
  const pageProperties = useFrontPage((x) => x.page?.properties);
  const properties = pageProperties ?? siteProperties;

  const show = properties.FooterEnabled || !properties.Whitelabeled;

  if (!show) {
    return null;
  }

  return (
    <Box
      component="footer"
      className={style.footerWrapper}
      bg={properties.FooterBackgroundColor}
      c={properties.FooterTextColor}
    >
      <a id="site-footer" />
      <Box className={style.footerContent} c={properties.FooterTextColor}>
        {!!properties.FooterBrandImage && properties.FooterLogoEnabled && (
          <div className={style.footerLogoWrapper}>
            <Image
              className={style.footerLogoImg}
              image={properties.FooterBrandImage}
              objectFit="contain"
              style={{
                height: '50px',
                maxHeight: '50px',
                width: 'auto'
              }}
            />
          </div>
        )}

        {!!properties.FooterContent && (
          <div className={style.footerText}>
            <HtmlRenderer html={properties.FooterContent} />
          </div>
        )}

        {properties.SocialNetworkEnabled && (
          <SiteFooterSocial
            textColor={properties.FooterTextColor}
            facebookUrl={properties.FacebookUrl}
            twitterUrl={properties.TwitterUrl}
            instagramUrl={properties.InstagramUrl}
            linkedinUrl={properties.LinkedinUrl}
            youtubeUrl={properties.YoutubeUrl}
            webSiteUrl={properties.WebSiteUrl}
            tikTokUrl={properties.TikTokUrl}
            snapchatUrl={properties.SnapchatUrl}
            redditUrl={properties.RedditUrl}
            pinterestUrl={properties.PinterestUrl}
          />
        )}

        {properties.Whitelabeled ? null : <PoweredBy />}
      </Box>
    </Box>
  );
};
